export class DisclaimerService {
  private static contactKey = 'contact-disclaimer';
  private static loginKey = 'login-disclaimer';

  public static getContact(): boolean {
    return localStorage.getItem(this.contactKey) === 'true';
  }

  public static setContact(value: boolean): void {
    localStorage.setItem(this.contactKey, `${value}`);
  }

  public static getLogin(): boolean {
    return localStorage.getItem(this.loginKey) === 'true';
  }

  public static setLogin(value: boolean): void {
    localStorage.setItem(this.loginKey, `${value}`);
  }
}

import { Observable, from } from 'rxjs';
import { axios } from '@core/axios';
import { map } from 'rxjs/operators';
import { Profile } from '@model/profile';

import { MessageResponse } from '@model/response/@base-response/dipo-back-base-response';
import { DIPOBackBaseResponse } from '@model/response/@base-response/dipo-back-base-response';
import { ForgetPasswordRequest } from '@model/request/forget-password.request';
import { LeftMenuItem } from '@model/left-menu';
import { flatten } from '@helpers/@utils/transform-menu';
import { flattenDeepestLevelNames } from '@helpers/@utils/flatten-deepest-level-names';
import { DashboardMenuKeys } from '@constants/menu-keys';
import { DisclaimerRequest } from '@model/request/disclaimer.request';

export interface Permission {
  mainMenus: LeftMenuItem<DashboardMenuKeys>[];
  permission: DashboardMenuKeys[];
}

export class ProfileService {
  private static baseUrl = '/user';
  public static getProfile(): Observable<Profile> {
    const url = `${this.baseUrl}`;
    return from(axios.get<DIPOBackBaseResponse<Profile>>(url)).pipe(map(({ data }) => data.data[0]));
  }
  public static getChangePassword(jwt: string): Observable<boolean> {
    const url = `${this.baseUrl}/change-password`;
    return from(
      axios.get<DIPOBackBaseResponse<boolean>>(url, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
    ).pipe(map(({ data }) => data.data[0]));
  }

  public static updatePassword(body: ForgetPasswordRequest): Observable<MessageResponse> {
    const url = `${this.baseUrl}/change-password`;
    return from(axios.put<MessageResponse>(url, body)).pipe(map((d) => d.data));
  }

  public static getMainMenu(): Observable<Permission> {
    const url = `${this.baseUrl}/main-menu`;
    return from(axios.get<DIPOBackBaseResponse<LeftMenuItem<DashboardMenuKeys>>>(url)).pipe(
      map(({ data }) => data.data),
      map(
        (data) =>
          ({
            mainMenus: flatten(data),
            permission: flattenDeepestLevelNames(data),
          } as Permission)
      )
    );
  }

  public static postDisclaimer(body: DisclaimerRequest): Observable<MessageResponse> {
    const url = `${this.baseUrl}/disclaimer`;
    return from(axios.post<MessageResponse>(url, body)).pipe(map((d) => d.data));
  }
}

import React from 'react';
import { BreadcrumbRouteProps } from '@mis/sushi-tailwind-react';
import { DefaultGenerics, MakeGenerics, Navigate, Route } from '@tanstack/react-location';
import { SecurityType } from '@model/enum/security-type.enum';
import { Profile } from '@model/profile';
import getDefaultData, { DefaultData } from '@helpers/@utils/get-default-data';
import { Permission } from '@core/services/profile.service';
import getReservedSymbolDefaultData, { ReservedSymbolDefaultData } from '@containers/reserved-symbol/api/default-data';
import { ReservedSymbolType } from '@model/reserved-symbol';
import { AssignedTeamResponse } from '@model/response/assigned-team.response';
import { ApplicationStatus } from '@model/application-status';
import { ApplicationMenuItem } from '@model/application-menu-item';
import getApplicationDefaultData, { ApplicationDefaultData } from '@containers/application/api/default-data';
import { ApplicationAccess } from '@model/response/check-application-access';
import { DraftProfileResponse } from '@model/response/draft-profile.response';

type Breadcrumb = BreadcrumbRouteProps & {
  next?: string | null;
  back?: string | null;
};

export type DefaultLocationGenerics = MakeGenerics<{
  Params: {
    symbolId: string;
    securityType: SecurityType;
  };
  LoaderData: { passwordExpired: boolean; profile: Profile; permission: Permission };
}>;

export type ReservedSymbolLocationGenerics = MakeGenerics<{
  Params: {
    symbolId: string;
    securityType: SecurityType;
  };
  LoaderData: {
    profile: Profile;
    passwordExpired: boolean;
    menus: ApplicationMenuItem[];
    store: {
      reservedSymbol: DraftProfileResponse<ReservedSymbolType>;
      assignedTeam: AssignedTeamResponse;
      appStatus: ApplicationStatus;
      applicationAccess?: ApplicationAccess;
    };
  };
}>;

export type ContactDefaultLocationGenerics = MakeGenerics<{
  Params: {
    contactType: string;
    contactId: string;
  };
}>;

const defaultLoader = {
  loader: async (): Promise<Partial<DefaultData>> => {
    return await getDefaultData();
  },
};

const symbolLoader = {
  loader: async ({ params: { symbolId, securityType } }: AnyValue): Promise<Partial<ReservedSymbolDefaultData>> => {
    return await getReservedSymbolDefaultData(symbolId, securityType);
  },
};

const routers: Route<DefaultGenerics>[] = [
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
  {
    path: '/',
    element: () => import('./pages/landing').then((mod) => <mod.default />),
  },
  {
    path: '/auth',
    element: () => import('./pages/auth').then((mod) => <mod.default />),
  },
  {
    path: '/sam/auth',
    element: () => import('./pages/sam/auth').then((mod) => <mod.default />),
  },
  {
    path: '/logout',
    element: () => import('./pages/logout').then((mod) => <mod.default />),
  },
  {
    path: '/unauthorized',
    element: () => import('./pages/unauthorized').then((mod) => <mod.default />),
  },
  {
    path: '/not-found',
    element: () => import('./pages/notFound').then((mod) => <mod.default />),
  },
  {
    path: '/disclaimer',
    element: () => import('./pages/disclaimer').then((mod) => <mod.default />),
    ...defaultLoader,
  },
  {
    path: '/change-password',
    element: () => import('./pages/changePassword').then((mod) => <mod.default />),
    ...defaultLoader,
  },
  {
    path: 'dashboard',
    element: () => import('./pages/dashboard').then((mod) => <mod.default />),
    ...defaultLoader,
  },
  {
    path: 'check-symbol-name',
    element: () => import('./pages/checkSymbolName').then((mod) => <mod.default />),
    meta: {
      breadcrumb: (): Breadcrumb[] => {
        return [{ label: 'Home', href: '/dashboard' }, { label: 'จองชื่อย่อพิเศษ' }];
      },
    },
    ...defaultLoader,
  },
  {
    path: 'symbol/:securityType',
    children: [
      {
        path: 'reserve',
        element: () => import('./pages/reservedSymbol/reserve').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }, { label: 'เพิ่มข้อมูลบริษัท' }, { label: 'จองชื่อย่อ' }];
          },
        },
        ...symbolLoader,
      },
      {
        path: ':symbolId',
        element: () => import('./pages/reservedSymbol/:symbolId').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (companyName: string): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }, { label: companyName }];
          },
        },
        children: [
          {
            path: 'reserve',
            element: () => import('./pages/reservedSymbol/:symbolId/reserve/index').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'จองชื่อย่อ' }];
              },
            },
          },
          {
            path: 'fa',
            element: () => import('./pages/reservedSymbol/:symbolId/fa').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (_companyName: string, securityType: SecurityType): Breadcrumb[] => {
                return [
                  { label: 'ข้อมูลผู้ดูแล' },
                  { label: `translation:reserved_symbol.node.stakeholder.financial_advisor.title.${securityType}` },
                ];
              },
            },
          },
          {
            path: 'auditor',
            element: () => import('./pages/reservedSymbol/:symbolId/auditor').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'ข้อมูลผู้ดูแล' }, { label: 'ผู้สอบบัญชี' }];
              },
            },
          },
          {
            path: 'underwriter',
            element: () => import('./pages/reservedSymbol/:symbolId/underwriter').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'ข้อมูลผู้ดูแล' }, { label: 'ผู้จัดการการจัดจำหน่าย' }];
              },
            },
          },
          {
            path: 'sec',
            element: () => import('./pages/reservedSymbol/:symbolId/sec').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'ข้อมูลผู้ดูแล' }, { label: 'สำนักงาน ก.ล.ต.' }];
              },
            },
          },
          {
            path: 'set',
            element: () => import('./pages/reservedSymbol/:symbolId/set').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'ข้อมูลผู้ดูแล' }, { label: 'ตลาดหลักทรัพย์แห่งประเทศไทย' }];
              },
            },
          },
          {
            path: 'timeline',
            element: () => import('./pages/reservedSymbol/:symbolId/timeline').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'Timeline' }];
              },
            },
          },
          {
            path: 'create-application',
            element: () => import('./pages/reservedSymbol/:symbolId/createApplication').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'สร้างแบบคำขอและ Filing' }];
              },
            },
          },
        ],
        ...symbolLoader,
      },
    ],
  },
  {
    path: 'application/:appId',
    element: () => import('./pages/application').then((mod) => <mod.default />),
    children: [
      {
        path: 'company-profile',
        element: () => import('./pages/application/companyProfile').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'เตรียมข้อมูล' }, { label: 'ข้อมูลบริษัท' }];
          },
        },
      },
      {
        path: 'security-profile',
        element: () => import('./pages/application/securityProfile').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'เตรียมข้อมูล' }, { label: 'ข้อมูลหลักทรัพย์' }];
          },
        },
      },
      {
        path: 'authorized-profile',
        element: () => import('./pages/application/authorizedProfile').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'เตรียมข้อมูล' }, { label: 'ข้อมูลผู้มีอำนาจลงนาม' }];
          },
        },
      },
      {
        path: 'digital-sign-management',
        element: () => import('./pages/application/digitalSignManagement').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'เตรียมข้อมูล' }, { label: 'รูปแบบการลงนาม' }];
          },
        },
      },
      {
        path: 'submission-method',
        element: () => import('./pages/application/submissionMethod').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'เตรียมข้อมูล' }, { label: 'วิธีการยื่น' }];
          },
        },
      },

      {
        path: 'major-shareholders',
        element: () => import('./pages/application/majorShareholder').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'รายงานหลังการขายหลักทรัพย์' }, { label: 'รายงานข้อมูลผู้ถือหุ้นที่เปิดเผยตามเกณฑ์' }];
          },
        },
      },
      {
        path: 'information-memorandum',
        element: () => import('./pages/application/informationMemorandum').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'รายงานหลังการขายหลักทรัพย์' }, { label: 'สรุปข้อสนเทศ' }];
          },
        },
      },
    ],
    meta: {
      breadcrumb: (companyName: string): Breadcrumb[] => {
        return [{ label: 'Home', href: '/dashboard' }, { label: companyName }];
      },
    },
    loader: async ({ params: { appId } }: AnyValue): Promise<Partial<ApplicationDefaultData>> => {
      return await getApplicationDefaultData(appId);
    },
  },
  {
    path: 'contact',
    element: () => import('./pages/contact').then((mod) => <mod.default />),
    children: [
      {
        path: 'create/:contactType',
        element: () => import('./pages/contact/create').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }, { label: 'contact.title' }, { label: 'contact.node.create.title' }];
          },
        },
      },
      {
        path: 'edit/:contactId/:contactType',
        element: () => import('./pages/contact/edit').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }, { label: 'contact.title' }, { label: 'contact.node.edit.title' }];
          },
        },
      },
      {
        path: 'manage',
        element: () => import('./pages/contact/manage').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'Home', href: '/dashboard' }, { label: 'การจัดการข้อมูลผู้ติดต่อ' }];
          },
        },
        children: [
          {
            path: 'ceo',
            element: () => import('./pages/contact/manage/ceo').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'contact.node.ceo.title' }];
              },
            },
          },
          {
            path: 'event',
            element: () => import('./pages/contact/manage/event').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'contact.node.event.title' }];
              },
            },
          },
          {
            path: 'set-key-contact',
            element: () => import('./pages/contact/manage/set-key-contact').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [{ label: 'contact.node.set-key-contact.title' }];
              },
            },
          },
        ],
      },
    ],
    ...defaultLoader,
  },
  {
    path: 'admin',
    element: () => import('./@components/permission/SuperAdminPermission').then((mod) => <mod.default />),
    children: [
      {
        path: 'report',
        children: [
          {
            path: 'contact',
            element: () => import('./pages/admin/report/contact').then((mod) => <mod.default />),
            meta: {
              breadcrumb: (): Breadcrumb[] => {
                return [
                  { label: 'Home', href: '/dashboard' },
                  { label: 'admin.report.title' },
                  { label: 'admin.report.node.contact.title' },
                ];
              },
            },
          },
        ],
      },
    ],
    ...defaultLoader,
  },
  {
    path: 'download',
    element: () => import('./pages/download').then((mod) => <mod.default />),
    meta: {
      breadcrumb: (): Breadcrumb[] => {
        return [{ label: 'Home', href: '/dashboard' }, { label: 'downloadDocument.title' }];
      },
    },
    children: [
      {
        path: 'digital-ipo',
        element: () => import('./pages/download/digitalIPO').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'downloadDocument.digital-ipo.title' }];
          },
        },
      },
      {
        path: 'relevant',
        element: () => import('./pages/download/relevantDocument').then((mod) => <mod.default />),
        meta: {
          breadcrumb: (): Breadcrumb[] => {
            return [{ label: 'downloadDocument.relevant.title' }];
          },
        },
      },
    ],
    ...defaultLoader,
  },
  // {
  //   path: 'redirect',
  //   children: [
  //     { path: 'major-shareholder/:appId', element: () => import('./pages/redirect/majorShareholder').then((mod) => <mod.default />) },
  //   ],
  //   ...defaultLoader,
  // },
  {
    path: 'report',
    children: [
      { path: 'major-shareholder/:appId', element: () => import('./pages/report/majorShareholder/:appId').then((mod) => <mod.default />) },
    ],
    ...defaultLoader,
  },
  {
    path: 'preview',
    children: [
      {
        path: 'application/:appId',
        element: () => import('./pages/unlockPreview/application').then((mod) => <mod.default />),
        children: [
          {
            path: 'company-profile',
            element: () => import('./pages/unlockPreview/application/:applicationId/companyProfile').then((mod) => <mod.default />),
          },
          {
            path: 'security-profile',
            element: () => import('./pages/unlockPreview/application/:applicationId/securityProfile').then((mod) => <mod.default />),
          },
          {
            path: 'authorized-profile',
            element: () => import('./pages/unlockPreview/application/:applicationId/authorizedProfile').then((mod) => <mod.default />),
          },
        ],
        loader: async ({ params: { appId } }: AnyValue): Promise<Partial<ApplicationDefaultData>> => {
          return await getApplicationDefaultData(appId);
        },
      },
      {
        path: 'symbol/:securityType/:symbolId',
        element: () => import('./pages/unlockPreview/symbol').then((mod) => <mod.default />),
        children: [
          {
            path: 'auditor',
            element: () => import('./pages/unlockPreview/symbol/:securityType/:symbolId/auditor').then((mod) => <mod.default />),
          },
        ],
        ...symbolLoader,
      },
    ],
  },
];

export default routers;

import { ApplicationService } from '@core/services/application.service';
import { ProfileService } from '@core/services/profile.service';
import { ReservedSymbolService } from '@core/services/reserved-symbol.service';
import { TokenService } from '@core/services/token.service';
import { ApplicationMenuItem } from '@model/application-menu-item';
import { SecurityType } from '@model/enum/security-type.enum';
import { Profile } from '@model/profile';
import { ReservedSymbolType } from '@model/reserved-symbol';
import { ApplicationAccess } from '@model/response/check-application-access';
import { DraftProfileResponse } from '@model/response/draft-profile.response';

import { firstValueFrom, forkJoin, of, retry, switchMap } from 'rxjs';

export interface ApplicationDefaultData {
  profile: Profile;
  passwordExpired: boolean;
  application: {
    reservedSymbolId: number;
    securityType: SecurityType;
    reservedSymbol: DraftProfileResponse<ReservedSymbolType>;
    menus: ApplicationMenuItem[] | null;
    applicationAccess: ApplicationAccess;
  } | null;
}

export default async function getApplicationDefaultData(applicationId: string): Promise<Partial<ApplicationDefaultData>> {
  const jwt = TokenService.getToken();
  const data$ = ProfileService.getProfile().pipe(
    retry({ count: 1, delay: 5000 }),
    switchMap((profile) =>
      forkJoin({
        profile: of(profile),
        passwordExpired: ProfileService.getChangePassword(jwt as string),
        application: !isNaN(Number(applicationId))
          ? ApplicationService.getMeta(Number(applicationId)).pipe(
              switchMap(({ reservedSymbolId, typeOfSecurities }) =>
                forkJoin({
                  reservedSymbolId: of(reservedSymbolId),
                  securityType: of(typeOfSecurities),
                  reservedSymbol: ReservedSymbolService.getSymbol(reservedSymbolId, typeOfSecurities),
                  menus: !isNaN(Number(reservedSymbolId)) ? ApplicationService.getMenus(Number(reservedSymbolId)) : of(null),
                  applicationAccess: ReservedSymbolService.checkApplicationAccess(Number(reservedSymbolId)),
                })
              )
            )
          : of(null),
      })
    )
  );
  return await firstValueFrom(data$);
}
